/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('typeface-pt-sans');
require('typeface-coiny');

exports.onServiceWorkerUpdateReady = () => {
  window.location.reload();
};
